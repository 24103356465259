import React from "react";
import Footer from "src/components/Footer";

class Page extends React.Component {
  render() {
    return (
      <div>
        <div className="boper">
          <div className="wrapper">
            <header>
              <h1 className="logo">
                <a href="/">Neutrogena</a>
              </h1>
              <nav>
                <a href="/produkty/" className="link">
                  Produkty
                </a>
                <span>| </span>
                <a href="/neutrogena/o-nas/" className="link">
                  NEUTROGENA<sup>®</sup>
                </a>
                <span>| </span>
                <a href="/budte-krasna/" className="link">
                  Buďte krásná
                </a>
                  
                  
              </nav>
            </header>
            <div className="top">
              <div className="slider" id="slider">
                <div className="slides_container">
                <div id="box_slide">
                    <div id="text_slide" className="sliderBox">
                      <p>
                        <a className="button" href="/category/Retinol-Boost/">
                          více
                        </a>
                      </p>
                    </div>
                    <a href="/#">
                      <img
                        src="/assets/Uploads/Banner_960x374_Retinol_Boost.jpg"
                        alt="www banner ps 960x374 CZ"
                      />
                    </a>
                  </div>
                <div id="box_slide">
                    <div id="text_slide" className="sliderBox">
                      <p>
                        <a className="button" href="/produkty/">
                          více
                        </a>
                      </p>
                    </div>
                    <a href="/#">
                      <img
                        src="/assets/Uploads/NeutrogenaBB-web960x374px.jpg"
                        alt="www banner ps 960x374 CZ"
                      />
                    </a>
                  </div>
                 <div id="box_slide">
                    <div id="text_slide" className="sliderBox">
                      <p>
                        <a className="button" href="/produkty/">
                          více
                        </a>
                      </p>
                    </div>
                    <a href="/#">
                      <img
                        src="/assets/Uploads/NTG-HB_960x374px.jpg"
                        alt="www banner ps 960x374 CZ"
                      />
                    </a>
                  </div>
                  <div id="box_slide">
                    <div id="text_slide" className="sliderBox">
                      <p>
                        <a className="button" href="/produkty/">
                          Více
                        </a>
                      </p>
                    </div>
                    <a href="/#">
                      <img
                        src="/assets/Uploads/NTG-CC_960x374px.jpg"
                        alt="CellularBoostCZ 960x374d"
                      />
                    </a>
                  </div>
                  
                  <div id="box_slide">
                    <div id="text_slide" className="sliderBox">
                      <p>
                        <a className="button ml" href="/produkty/">
                          Více
                        </a>
                      </p>
                    </div>
                    <a href="/#">
                      <img
                        src="/assets/Uploads/NTG-NF_960x374px.jpg"
                        alt="neutrogena www face body 07 cz orez bez buttonu bez splouchnuti"
                      />
                    </a>
                  </div>
                  
                  <div id="box_slide">
                    <div id="text_slide" className="sliderBox">
                      <p>&nbsp;</p>
                      <p>
                        <a className="button" href="/produkty/">
                          Více
                          <br />
                        </a>
                      </p>
                    </div>
                    <a href="/#">
                      <img src="/assets/Uploads/NTG-RUCENOHY_960x374pxnew.jpg" alt="slider1" />
                    </a>
                  </div>
                  {/* <div id="box_slide">
                    <div id="text_slide" className="sliderBox">
                      <p>
                        <a className="button ml" href="/produkty/">
                          Více
                          <br />
                        </a>
                      </p>
                    </div>
                    <a href="/#">
                      <img
                        src="/assets/Uploads/NTG-PLETOVE_960x374px.jpg"
                        alt="001 JOH 2019 skin Detox baner 960x374 03 cz bez buttonu"
                      />
                    </a>
                  </div> */}
                </div>
                <ol className="pagination dots"></ol>
              </div>
            </div>
            <div className="content">
              <ol className="mainboxes" id="mainboxes">
                <li>
                  <div className="case_prod_in first">
                    <a className="various1" href="#inline1">
                      <img src="/assets/Uploads/spot4.png" alt="spot4" />
                    </a>
                    <div
                      style={{
                        height: "90px"
                      }}
                    >
                      <a className="various1 title" href="#inline1">
                        <h2>Prohlédnout video</h2>
                      </a>
                    </div>
                    <div
                      className="newChanges"
                      style={{
                        height: "66px"
                      }}
                    >
                      {" "}
                    </div>
                    <p>
                      <a id="various2" className="button" href="#inline1">
                        Více
                      </a>
                    </p>
                  </div>
                  <div
                    style={{
                      display: "none"
                    }}
                  >
                    <div
                      id="inline1"
                    >
                      <video
                        id="player_a"
                        className="projekktor"
                        poster="/themes/neutrogenacz/projekktor/media/intro4.png"
                        title="this is Projekktor"
                        width="640"
                        height="385"
                        controls
                      >
                        <source
                          src="/themes/neutrogenacz/projekktor/media/Neutrogena_VR_Jen_Garner_cs_CZ_HD1080_25p_30.mp4"
                          type="video/mp4"
                        />
                      </video>
                    </div>
                  </div>
                </li>
                <li className="middle">
                  <a
                    href="/budte-krasna/sucha-pokozka/"
                    className="photo"
                    id="various1"
                  >
                    <img src="/assets/Uploads/case-norm.png" alt="case norm" />
                  </a>
                  <a
                    href="/budte-krasna/sucha-pokozka/"
                    className="title"
                  >
                    <p>Péče o suchou pokožku</p>
                  </a>
                  <div className="newChanges">
                    <p>
                      Hydratovaná a dobře vyživená pokožka je vizitkou každé
                      ženy.&nbsp;
                    </p>
                    <p>
                      <a className="button" href="/budte-krasna/sucha-pokozka/">
                        Více
                        <br />
                      </a>
                    </p>
                  </div>
                </li>
                <li
                  style={{
                    height: "460px"
                  }}
                >
                  <div className="photo" id="products2">
                    <div className="slides_container">
                      <div id="box_slide">
                        <a href="/budte-krasna/pece-o-plet/">
                          <img src="/assets/Uploads/twarz2.jpg" alt="twarz2" />
                        </a>
                        <div id="text_slide">
                          <a
                            href="/budte-krasna/pece-o-plet/"
                            className="title"
                          >
                            <p>Péče o pleť</p>
                          </a>
                          <div className="newChanges">
                            {" "}
                            <p>
                              Chcete mít pěknou a zdravou pleť? Zjistěte, čím je
                              to ovlivněno.
                            </p>
                            <p>
                              <a
                                className="button"
                                href="/budte-krasna/pece-o-plet/#aboutAnchor"
                              >
                                Více
                                <br />
                              </a>
                            </p>
                          </div>
                        </div>
                      </div>
                      <div id="box_slide">
                        <a href="/budte-krasna/pece-o-rty/">
                          <img src="/assets/Uploads/lips.jpg" alt="lips" />
                        </a>
                        <div id="text_slide">
                          <a
                            href="/budte-krasna/pece-o-rty/"
                            className="title"
                          >
                            <p>Péče o rty</p>
                          </a>
                          <div className="newChanges">
                            {" "}
                            <p>
                              Rty jsou nejvíce zanedbané části našeho těla. Co
                              je potřeba o nich vědet?
                            </p>
                            <p>
                              <a
                                className="button"
                                href="/budte-krasna/pece-o-rty/#aboutAnchor"
                              >
                                Více
                              </a>
                            </p>
                          </div>
                        </div>
                      </div>
                      <div id="box_slide">
                        <a href="/budte-krasna/pece-o-ruce/">
                          <img
                            src="/assets/Uploads/dlonie4.jpg"
                            alt="dlonie4"
                          />
                        </a>
                        <div id="text_slide">
                          <a
                            href="/budte-krasna/pece-o-ruce/"
                            className="title"
                          >
                            <p>Péče o ruce</p>
                          </a>
                          <div className="newChanges">
                            {" "}
                            <p>
                              Jak účinně pečovat o své ruce a udržet co nejdéle
                              jejich mladý vzhled?
                            </p>
                            <p>
                              <a
                                className="button"
                                href="/budte-krasna/pece-o-ruce/#aboutAnchor"
                              >
                                Více
                              </a>
                            </p>
                          </div>
                        </div>
                      </div>
                      <div id="box_slide">
                        <a href="/budte-krasna/pece-o-nohy/">
                          <img src="/assets/Uploads/stopy3.jpg" alt="stopy3" />
                        </a>
                        <div id="text_slide">
                          <a
                            href="/budte-krasna/pece-o-nohy/"
                            className="title"
                          >
                            <p>Péče o nohy</p>
                          </a>
                          <div className="newChanges">
                            {" "}
                            <p>
                              Pečující rituál, který vám umožní užít si krásné
                              nohy po celý rok.
                            </p>
                            <p>
                              <a
                                className="button"
                                href="/budte-krasna/pece-o-nohy/#aboutAnchor"
                              >
                                Více
                                <br />
                              </a>
                            </p>
                          </div>
                        </div>
                      </div>
                      <div id="box_slide">
                        <a href="/budte-krasna/pece-o-tlo/">
                          <img src="/assets/Uploads/ciao.jpg" alt="ciao" />
                        </a>
                        <div id="text_slide">
                          <a
                            href="/budte-krasna/pece-o-tlo/"
                            className="title"
                          >
                            <p>Péče o tělo</p>
                          </a>
                          <div className="newChanges">
                            {" "}
                            <p>
                              Zdravá a krásná pokožka vyžaduje promyšlenou péči
                              o každý den.
                            </p>
                            <p>
                              <a
                                className="button"
                                href="/budte-krasna/pece-o-tlo/#aboutAnchor"
                              >
                                Více
                                <br />
                              </a>
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </li>
              </ol>
            </div>
            <div id="fb_1"></div>
          </div>
        </div>
        <Footer></Footer>
        <script
          dangerouslySetInnerHTML={{
            __html:
              "\n$(document).ready(function () {\n$('a').each(function () {\nvar linkhtml = $(this).html();\nif (linkhtml.indexOf('®') > -1 && linkhtml.indexOf('<sup>®</sup>') == -1) {\n$(this).html(linkhtml.replace(\"®\", \"<sup>®</sup>\"));\n}\n});\n});\n"
          }}
        />
        <script src="/themes/neutrogenacz/js/slides.min.jquery.js" />
        <script src="/themes/neutrogenacz/js/slide.js" />
        <script
          dangerouslySetInnerHTML={{
            __html:
              "\n$(document).ready(function () {\n$('a').each(function () {\nvar linkhtml = $(this).html();\nif (linkhtml.indexOf('®') > -1 && linkhtml.indexOf('<sup>®</sup>') == -1) {\n$(this).html(linkhtml.replace(\"®\", \"<sup>®</sup>\"));\n}\n});\n});\n"
          }}
        />

      </div>
    );
  }
}

export default Page;